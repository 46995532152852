import Types from "../type"
import type { ISetting } from "../util/setting"
import { call } from "../util/call.api"

const getAccount = async (setting: ISetting) => {
	const result = await call(Types.GET_ACCOUNT_SERVER, setting)

	return result
}

export { getAccount }
